
import { isEmpty, isNumeric, validateRange, rangeDigits } from '../../config/utils';

class FormDataValue {
  constructor({ age, gender, code }) {
    this.age = age;
    this.gender = gender;
    this.code = code;
  }

  buildFormData () {
    const age = {
      value: this.age,
      messageError: null,
      isValid: this.age !== null ? true : false,
      validations: [ isEmpty, isNumeric, validateRange ]
    }

    const gender = {
      value: this.gender,
      messageError: null,
      isValid: this.gender !== null ? true : false,
      validations: [isEmpty]
    }

    const code = {
      value: this.code,
      messageError: null,
      isValid: this.code !== null ? true : false,
      validations: [ isEmpty, isNumeric, rangeDigits ]
    }

    return { age, gender, code }
  }

  getData () {
    return this.buildFormData();
  }
}

export default FormDataValue;