import React from 'react';
import Lottie from 'react-lottie';
import animationsData from './animationsData';
import './animation.scss';

const Animation = ({type, className}) => {

  const animationData = animationsData[type] || {};

  const defaultOptions = {
    loop: false,
    animationData: animationData.data
  };

  return (
    <div className={'animation ' + className}>
      <div
        className="aspect-ratio"
        style={{paddingTop: (animationData.aspectRatio * 100) + '%'}}>
        <div className="aspect-ratio-content">
          <div
            className="lottie"
            style={{top: (animationData.topOffsetRatio * 100) + '%'}}>
            <Lottie
              options={defaultOptions}/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Animation;
