import React from 'react';
import './backToTest.scss';
import face_black from '../../../img/face.png';
import face_white from '../../../img/congrat-face-alt.png';

const BackToTest = ({color, handleNavigation}) => {

  return (
    <div className="back" onClick={ () => { handleNavigation() } }>
      <img className="back-img" src={color === 'white' ? face_white : face_black} alt="face" />
      <p className={`${color === 'white' ? 'back-txt-white' : 'back-txt-black'}`}>Volver al Test</p>
    </div>
  )
};

export default BackToTest;
