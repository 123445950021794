import React from 'react';
import AnimatedIntroView from '../../Common/AnimatedIntroView';
import './animated-intro-security.scss';

import config from '../../../config/config';
const NEXT_PAGE = 'questionSecurity';

const AnimatedIntroSecurity = ({managePage}) => (
  <AnimatedIntroView
    className="animated-intro-security"
    themeColor="#c95631"
    title={ config.security.title }
    firstAnimationType="securityIntro"
    firstAnimationDuration={5000}
    description={ config.security.description }
    secondAnimationType="roundedAnswers"
    instructions="Pulsa en la opción elegida"
    onStartPressed={() => {managePage(NEXT_PAGE)}}
    testConfigKey="fakeNews"/>
);

export default AnimatedIntroSecurity;
