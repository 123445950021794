import React from 'react';
import AnimatedIntroView from '../../Common/AnimatedIntroView';
import './animated-intro-adiction.scss';
import config from '../../../config/config';

const NEXT_PAGE = 'testAdiction';

const AnimatedIntroAdiction = ({managePage}) => (
  <AnimatedIntroView
    className="animated-intro-adiction"
    themeColor="#bf4d4d"
    title={ config.adiction.title }
    firstAnimationType="addictionIntro"
    firstAnimationDuration={3000}
    description={ config.adiction.description }
    secondAnimationType="addictionTextSlots"
    instructions="Utiliza tu teclado"
    onStartPressed={() => {managePage(NEXT_PAGE)}}
    testConfigKey="habitChanges"/>
);

export default AnimatedIntroAdiction;
