import React from 'react';
import QuestionsView from '../../Common/QuestionsView';

const NEXT_PAGE = 'result';

const QuestionSecurity = ({managePage, resetScrollFn}) => {

  return (
    <QuestionsView
      testIndex={4}
      configItemName="security"
      resetScrollFn={resetScrollFn}
      onFinish={() => managePage(NEXT_PAGE)}
      colorTitle="color-title-black" />
  );
};

export default QuestionSecurity;
