export const testEndpoint = process.env.REACT_APP_BACKEND_BASEURL + '/test';

export const beatsEndpoint = process.env.REACT_APP_BACKEND_BASEURL + '/beats-per-minute';

export const shareEndpoint = process.env.REACT_APP_BACKEND_BASEURL + '/result';

export const isEmpty = (field) => !field.length ? 'Campo obligatorio' : '';

export const isNumeric = (field) => typeof field === 'number' ? '' : 'Tiene que ser un número';

export const validateRange = (field) => {
  if (field > 110) {
    return `No puedes ser mayor de ${110}`
  }

  if (field < 14 ) {
    return `Tienes que ser mayor de ${14} años para poder continuar`
  }

  return '';
}

export const rangeDigits = (field) => field.length !== 5 || field < 0 ? 'Código postal incorrecto' : '';

export const isEmail = (field) => (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(field)) ? '' : 'Email incorrecto';

export const isChecked = (field) => field ? '' : 'Campo obligatorio'

export const getDurationSecs = startTimestamp => startTimestamp ?
  Math.round((+new Date() - startTimestamp) / 1000) : 0;

export const totalTest = 5;

export const userData = {
  startTimestamp: null,
  captchaToken: null,
  userInfo: {
    age: null,
    gender: null,
    zipCode: null,
  },
  habitChanges: [],
  adiction: [],
  privacity: [],
  fakeNews: [],
  security: [],
  hasData: false,
  results: {
    habitsSum: 0,
    deg: 0
  }
};

export const calculateGrade = (habitChanges) => {
  const g = [0, 0, 0, 0, 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 70, 80, 90];

  let habitPonderation = 0;
  habitChanges.forEach(habit => {
    habitPonderation += habit.ponderation;
  });

  return {sum: habitPonderation, deg: g[habitPonderation]};
};

export const getUserAgentKey = () => {
  if (/android/i.test(navigator.userAgent)) {
    return 1; // Android
  } else if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    return 2; // iOS
  } else if (!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) {
    return 3; // Desktop chrome
  } else if (typeof InstallTrigger !== 'undefined') {
    return 4; // Firefox
  } else {
    return 5; // Other
  }
};

export const getRandomIndexsOfArray = (numberOfRandoms, arr) => {
  const positions = Array.from(Array(arr.length), (_, index) => index);
  const randomIndexs = [];

  while (randomIndexs.length < numberOfRandoms) {
    const randomIndex = Math.floor(Math.random() * positions.length);
    randomIndexs.push(positions[randomIndex]);
    positions.splice(randomIndex, 1);
  }

  return randomIndexs;
};
