import React from 'react';
import AnimatedIntroView from '../../Common/AnimatedIntroView';
import './animated-intro-privacity.scss';
import config from '../../../config/config';

const NEXT_PAGE = 'gamePrivacity';

const AnimatedIntroPrivacity = ({managePage}) => (
  <AnimatedIntroView
    className="animated-intro-privacity"
    themeColor="#00b97c"
    title={ config.privacity.title }
    firstAnimationType="privacityIntro"
    firstAnimationDuration={4000}
    description={ config.privacity.description }
    secondAnimationType="privacityGame"
    instructions="Selecciona y arrastra"
    onStartPressed={() => {managePage(NEXT_PAGE)}}
    testConfigKey="adiction"/>
);

export default AnimatedIntroPrivacity;
