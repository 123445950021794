import React, {useState, useEffect, useMemo, useCallback} from 'react';
import { Check } from '../Field';
import { getRandomIndexsOfArray } from '../../../config/utils';
import './answers-list.scss';

const AnswersList = ({idQuestion, classname, answers, handleChange}) => {

  const initIsChecked = useMemo(
    () => answers.map(() => false),
    [answers]);

  const [isChecked, setIsChecked] = useState(initIsChecked);
  const [sortedAnswers, setSortedAnswers] = useState([]);

  const getAnswers = useCallback(() =>
    getRandomIndexsOfArray(answers.length, answers).map(i => ({...answers[i], order: i + 1}))
  , [answers]);

  useEffect(() => {
    setIsChecked(initIsChecked);
    setSortedAnswers(getAnswers());
  }, [initIsChecked, getAnswers]);

  const processOnChange = (elem, i) => {
    handleChange(elem.checked && !!sortedAnswers.length ? sortedAnswers[elem.value - 1].order : '');

    setIsChecked(() => {
      const r = [...initIsChecked];
      r[i] = elem.checked;
      return r;
    })
  };

  return (
    <ul className="answers-list">
      { !!sortedAnswers.length && sortedAnswers.map((item, i) => (
        <li key={ i }>
          <Check
            type="checkbox"
            classname={ classname }
            name={ `${idQuestion}` }
            labelText={ item.text }
            value={ `${i+1}` }
            id={ `${idQuestion}-${i}` }
            checked={isChecked[i]}
            onchange={ (e) => {  processOnChange(e.target, i); } }
          />
        </li>
      ))}
    </ul>
  )
};

export default AnswersList;
