import React  from 'react';
import './browser-not-supported.scss';

const BrowserNotSupported = () => (
  <div className="browser-not-supported">
    <div className="message-container">
      <div className="message">
        <p>El test de Levanta la Cabeza no está disponible para este navegador.</p>
        <p>Por favor, acceda con otro navegador como Google Chrome o Mozilla Firefox.</p>
      </div>
    </div>
  </div>
);

export default BrowserNotSupported;
