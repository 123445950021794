import React, {useEffect, useState, useContext} from 'react';
import axios from 'axios';
import { Input } from '../Common/Field';
import Button from '../Common/Button';
import Modal from '../Common/Modal';
import {isEmpty, isEmail, testEndpoint} from '../../config/utils';
import {ContextData} from '../Common/Context';
import BackToTest from '../Common/BackToTest';

import './subscription.scss';

const RESULT_PAGE = 'result';

const Subscription = ({ managePage }) => {
  const [ formData, setFormData ] = useState({
    email: null,
    subscribe: false
  })

  const [ errorFields, setErrorFields ] = useState({
    email: {
      isValid: false,
      message: '',
    }
  })

  const [ modal, setModal ] = useState({ modalText: '', modalVisible: false })
  const [ acceptPolicy, setAcceptPolicy ] = useState(false);

  const {testId} = useContext(ContextData);

  useEffect(() => {
    document.getElementById('theme-color').content = '#d7d7d7';
  }, []);

  const handleMessage = (field, value, validations) => {
    let message, i = 0;

    do {
      const messageError = validations[i](value);
      message = messageError;
      i++;
    } while ( i < validations.length && !message.length)

    setErrorFields( () => {
      errorFields[field].message = message;
      errorFields[field].isValid = message.length ? false : true;

      return { ...errorFields }
    })
  }

  const handleChange = (field, value, validations) => {
    setFormData( () => {
      formData[field] = value;

      setFormData({ ...formData });
    })

    if (validations) {
      handleMessage(field, value, validations)
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (errorFields.email.isValid) {
      axios.patch(testEndpoint, {
        testId: testId,
        email: formData.email
      })
      .then((res) => {
        setModal(() => {
          modal['modalText'] = 'Se ha suscrito correctamente';
          modal['modalVisible'] = true;
          setTimeout(() => {
            managePage('thanksSubscribed');
          }, 3000);
          return {...modal}
        })
      })
      .catch((err) => {
        setModal(() => {
          modal['modalText'] = 'Ha habido un error al procesar su suscripción. Por favor, inténtelo más tarde.';
          modal['modalVisible'] = true;
          return {...modal}
        })
      });
    }
  }

  const isDisabled = errorFields.email.isValid && acceptPolicy ? false : true;

  return (
    <div className="subscription">
      <Modal
        text={ modal.modalText }
        visible={ modal.modalVisible }
        onclick={ () => {
          setModal(() => {
            modal['modalVisible'] = false;
            return { ...modal }
          })
        }}
      />
      <div>
        <h1 className="subscription-title">Suscríbete</h1>
        <h3 className="subscription-subtitle">A NUESTRAS NOVEDADES</h3>
        <p className="subscription-description">Cada mes recibirás en tu correo las últimas novedades y la actualización de las discusiones del comité 'Levanta la cabeza'</p>
      </div>
      <div>
        <form>
          <div className="subscription-field">
            <Input
              type="text"
              name="email"
              classname="input-primary"
              placeholder="Email"
              onblur={ (e) => {
                handleChange('email', e.target.value, [ isEmpty, isEmail ])
              }}
            />
            <div className="error">{ errorFields.email.message }</div>
          </div>
          <div className="subscription-field margin-fix">
            <div className="subscription-field-check margin-fix">
              <input type="checkbox" id="checkbox" className="subscription-checkbox" onChange={() => setAcceptPolicy(!acceptPolicy)}></input>
              <p className="subscription-field-check-txt">He leído y acepto expresamente la <a className="privacy-policy" href="https://compromiso.atresmedia.com/public/legal/politica-proteccion-datos-privacidad-levanta-la-cabeza.html" alt="proteccion-de-datos" target="_blank" rel="noopener noreferrer">política de protección de datos.</a></p>
            </div>
          </div>
          <div className="subscription-submit">
            <Button
              type="submit"
              text="Registrarse"
              classname="btn-light"
              disabled={ isDisabled }
              onclick={ (e) => { handleSubmit(e) } }
            />
          </div>
        </form>
        <p onClick={() => managePage('thanks')} className="subscription-no-subscription">No, gracias.</p>
      </div>
      <BackToTest handleNavigation={() => managePage(RESULT_PAGE)} color="black"></BackToTest>
    </div>
  )
};

export default Subscription;
