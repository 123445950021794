import React, {useState, useEffect, useContext} from 'react';
import './animated-intro-view.scss';
import Button from '../Button';
import Animation from '../Animation';
import axios from 'axios';
import { ContextData } from '../Context';
import {getDurationSecs, testEndpoint} from '../../../config/utils';
import {calculateGrade} from '../../../config/utils';

const AnimatedIntroView = ({className, themeColor, title, customTitle,
  firstAnimationType, firstAnimationDuration, description,
  secondAnimationType, instructions, onStartPressed, testConfigKey}) => {

  const [phase, setPhase] = useState(0);
  const data = useContext(ContextData);

  useEffect(() => {
    if (testConfigKey) {
      (async () => {

        try {

          const { testId, startTimestamp, [testConfigKey]: testData } = data;

          if (testConfigKey === 'habitChanges') {
            const result = calculateGrade(data.habitChanges);
            data.results.habitsSum = result.sum;
            data.results.deg = result.deg;
          }

          if (testId && testData && Array.isArray(testData)) {

            await axios.patch(testEndpoint, {
              testId: testId,
              duration: getDurationSecs(startTimestamp),
              [testConfigKey]: testData.map(r => ({
                testId: r.id,
                answerValue: r.value
              })),
              results: data.results
            });
          }

        } catch (e) {

          console.error('Error...', e);
        }
      })();
    }
  }, []);

  useEffect(() => {

    document.getElementById('theme-color').content = themeColor;

    setTimeout(() => {
      setPhase(1);
    }, firstAnimationDuration);

    setTimeout(() => {
      setPhase(2);
    }, firstAnimationDuration + 1000);

  }, [themeColor, firstAnimationDuration]);

  return (
    <div className={`animated-intro-view ${phase ? 'second-screen' : ''} ${className}`}>
      <div className="left-column">
        { title ?
          <div className="title">{title}</div> :
          customTitle }
        <div className="description">
          { description }
        </div>
      </div>
      <div className="right-column">
        <div className="first-animation-container">
          <Animation
            className="first-animation"
            type={firstAnimationType} />
        </div>
        { phase > 1 ?
          <Animation
            className="second-animation"
            type={secondAnimationType} /> : '' }
        <div className="instructions">
          <div className="soft-blinking">
            { instructions }
          </div>
        </div>
        <div className="start-button">
          <Button
            type="button"
            text="Comenzar"
            onclick={onStartPressed}/>
        </div>
      </div>
    </div>
  )
};

export default AnimatedIntroView;
