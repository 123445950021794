import React, {useEffect, useState} from 'react';
import Button from '../Common/Button';
import PropTypes from 'prop-types';
import { DataProvider, ContextData } from '../Common/Context';
import videoMobile from '../../video/horizontal.mp4';
import videoDesktop from '../../video/horizontal.mp4';
import fad from '../../img/fad-logo.png';
import './home.scss';

const NEXT_PAGE = 'about';

const Home = ({ managePage }) => {
  const [video, setVideo] = useState(null)

  useEffect(() => {
    document.getElementById('theme-color').content = '#b1464a';
    window.addEventListener("resize", changeScreenResolution);
    changeScreenResolution();

    return() => {
      window.removeEventListener("resize", changeScreenResolution)
    }
  }, []);

  const startPressHandler = (data) => {

    data.setFieldData(() =>
      data.fieldData['startTimestamp'] = +new Date()
    );

    managePage(NEXT_PAGE);
  };

  const changeScreenResolution = () => {
    const break_small = 768;
    window.innerWidth <= break_small ? setVideo(videoMobile) : setVideo(videoDesktop);
  }

  return (
    <DataProvider>
      <div className="video-container">
        <video className="video-background" playsInline loop autoPlay muted preload="auto" src={video} type="video/mp4" />
      </div>
      <div className="home-app">
        
        <h3>¿Tienes 5 minutos?</h3>
        <h2>Descubre si haces un uso responsable de la tecnología</h2>
        <div>
          <p>Test elaborado con la colaboración de FAD</p>
          <img className="fad-logo" src={fad} alt="fad"/>
          <ContextData.Consumer>
            {
              data => (
                <Button
                  text="Empezar"
                  classname="btn-terciary"
                  onclick={() => startPressHandler(data)}
                />
              )
            }
          </ContextData.Consumer>
        </div>
      </div>
    </DataProvider>
  );
};

Home.propTypes = {
  managePage: PropTypes.func
}

Home.defaultProps = {
  managePage: null
}

export default Home;
