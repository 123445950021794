import React, { useState, useEffect } from 'react';
import mail from './images/Mail.svg';
import facebook from './images/Facebook.svg';
import twitter from './images/Twitter.svg';
import {shareEndpoint} from '../../../config/utils';
// import arrow from './images/Arrow.svg';

const SocialNetworks = ({deg}) => {

  const [shareData, setShareData] = useState({});

  useEffect(() => {
    setShareData({
      url: `${shareEndpoint}/${deg}`,
      text: '¡Acabo de hacer el test de Levanta la Cabeza! Este es mi resultado: '
    });
  }, [deg]);

  const shareTwitter = () => {
    window.open('http://twitter.com/share?url='+encodeURIComponent(shareData.url)+'&text='+encodeURIComponent(shareData.text), '', 'left=0,top=0,width=550,height=450,personalbar=0,toolbar=0,scrollbars=0,resizable=0');
  }

  return (
    <React.Fragment>
      {/* <img src={arrow} alt="face"/> */ }
      {/* Facebook */}
      <a href={ `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareData.url)}&quote=${encodeURIComponent(shareData.text)}`} target="_blank">
        <img src={facebook} alt="facebook"/>
      </a>
      {/* Twitter */}
      <img src={twitter} alt="twitter" onClick={() => shareTwitter()}/>
      {/* Email */}
      <a href={"mailto:?subject=" + shareData.text + "&body=" + shareData.url}><img src={mail} alt="mail"/></a>
    </React.Fragment>
  )
};

export default SocialNetworks;
