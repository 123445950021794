import React, {useState, useEffect, useRef} from 'react';
import './typing-test-sentence.scss';

const TypingTestSentence = ({sentence, userInput, onMatch}) => {

  const sentenceWords = sentence.split(/ +/g);

  const feedbackInit = {state: 'next', wordIndex: 0};
  const [feedback, setFeedback] = useState(feedbackInit);

  const chronometer = useRef(0);

  useEffect(() => {
    setFeedback(feedbackInit);
    chronometer.current = 0;
  }, [sentence]);

  useEffect(() => {
    processPropsChanges(sentence, userInput);
  }, [sentence, userInput]);

  const getTextHtml = () => {

    const doneText = sentenceWords.slice(0, feedback.wordIndex).join(' ');
    const highlightWord = sentenceWords[feedback.wordIndex];
    const restText = sentenceWords.slice(feedback.wordIndex + 1).join(' ');

    return [
      <span key="1" className="done">{doneText}</span>, ' ',
      <span key="2" className={feedback.state}>{highlightWord}</span>, ' ',
      <span key="3">{restText}</span>
    ];
  };

  const processPropsChanges = (sentence, userInput) => {

    const normUserInput = normalizeString(userInput);

    if (normUserInput.startsWith(normalizeString(sentence))) {

      setFeedback({
        state: 'finished',
        wordIndex: sentenceWords.length
      });

      if (chronometer.current) {
        onMatch && onMatch(+new Date() - chronometer.current);
        chronometer.current = 0;
      }

    } else {

      const normSentenceWords = sentenceWords.map(
        w => normalizeString(w));

      if (!chronometer.current) {
        chronometer.current = +new Date();
      }

      const normUserInputWords = normalizeString(userInput).split(/ /g);

      let i;
      for(i = 0; i < normUserInputWords.length; i++) {
        if(normUserInputWords[i] !== normSentenceWords[i]) {
          break;
        }
      }

      if (normUserInputWords.length === i && !userInput.endsWith(' ')) {
        i--;
      }

      const isWarning = normUserInputWords.length - 1 > i ||
        !(normSentenceWords[i] || '').startsWith(normUserInputWords[i] || '');

      setFeedback({
        state: isWarning ? 'warning' : 'next',
        wordIndex: i
      });
    }
  };

  const normalizeString = (str) => {

    return str
      .replace(/[áàÁÀ]/g, 'a')
      .replace(/[éèÉÈ]/g, 'e')
      .replace(/[íìÍÌ]/g, 'i')
      .replace(/[óòÓÒ]/g, 'o')
      .replace(/[úùüÚÙÜ]/g, 'u')
      .toLowerCase();
  };

  return(
    <div className="typing-test-sentence">
      {getTextHtml()}
    </div>
  );
};

export default TypingTestSentence;
