import React from 'react';
import BackToTest from '../Common/BackToTest';
import './thanks.scss';

const RESULT_PAGE = 'result';

const Thanks = ({ managePage, hasSubscribed }) => {
  return (
    <div className="thanks">
      <div>
        <h1 className="thanks-title">¡Gracias!</h1>
        <p className="thanks-description">
          Gracias por
          {hasSubscribed && ' suscribirte a la newsletter y '} dedicar tu tiempo
          a realizar el test de Levanta la Cabeza, el movimiento por una
          digitalización sostenible de la sociedad española.
        </p>
      </div>
      <div className="thanks-container-back">
        <BackToTest
          handleNavigation={() => managePage(RESULT_PAGE)}
          color="white"
        ></BackToTest>
      </div>
    </div>
  );
};

export default Thanks;
