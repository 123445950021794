import React from 'react';
import AnimatedIntroView from '../../Common/AnimatedIntroView';
import './animated-intro-fake-news.scss';
import config from '../../../config/config';

const NEXT_PAGE = 'testFakeNews';

const AnimatedIntroFakeNews = ({managePage}) => (
  <AnimatedIntroView
    className="animated-intro-fake-news"
    themeColor="#edb721"
    title={ config.fakeNews.title }
    firstAnimationType="fakeNewsIntro"
    firstAnimationDuration={4000}
    description={ config.fakeNews.description }
    secondAnimationType="fakeNewsLongSwitch"
    instructions="Desliza a derecha o izquierda"
    onStartPressed={() => {managePage(NEXT_PAGE)}}
    testConfigKey="privacity"/>
);

export default AnimatedIntroFakeNews;
