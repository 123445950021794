import React, { useCallback, useEffect, useState } from 'react';
import { DataProvider, ContextData } from '../Context';
import QuestionItem from '../QuestionItem';
import Button  from '../Button';
import './questions-view.scss';
import config from '../../../config/config';
import StepsProgress from '../StepsProgress';
import TestsProgress from '../TestsProgress';
import { getRandomIndexsOfArray } from '../../../config/utils';

const QuestionsView = ({ testIndex, configItemName, resetScrollFn, onFinish, colorTitle }) => {

  const { title, questions, totalQuestions } = config[configItemName];
  const [ indexQuestion, setItemQuestion ] = useState(0);
  const [ answerChoose, setAnswerChoose ] = useState(null);
  const [ selectedQuestions, setSelectedQuestions ] = useState(null);

  const getNewQuestions = useCallback(() =>
    getRandomIndexsOfArray(totalQuestions, questions).map(i => questions[i]),
  [questions, totalQuestions]);

  useEffect(() => {
    setSelectedQuestions(getNewQuestions());
  }, [getNewQuestions]);


  const saveData = (indexQuestion, data) => {
    data.setFieldData(() => {
      data.fieldData[configItemName].push({
        id: selectedQuestions[indexQuestion].id,
        value: +answerChoose,
        ponderation: selectedQuestions[indexQuestion].answers[answerChoose - 1].ponderation
      });

      return { ...data.fieldData };
    })

    if (indexQuestion < (totalQuestions - 1)) {
      setItemQuestion(indexQuestion + 1)
      setAnswerChoose('')
      resetScrollFn && resetScrollFn();
    } else {
      onFinish && onFinish();
    }
  }

  return (
    <DataProvider>
      { selectedQuestions && <div className="questions-view">
        <TestsProgress index={testIndex}/>
        <div className="left">
          <h3 className={colorTitle}>{ title }</h3>
          <p>{ selectedQuestions[indexQuestion].text }</p>
          <StepsProgress
            current={ indexQuestion }
            total={ totalQuestions } />
        </div>
        <div className="right">
          <h3 className={colorTitle}>{ title }</h3>
          <QuestionItem
            classname={configItemName}
            item={ selectedQuestions[indexQuestion] }
            handleChange={ ( value ) => { setAnswerChoose(value) } }
          />
          <div className="button-container">
            <ContextData.Consumer>
              {
                data => (
                  <Button
                    text="Siguiente"
                    type="button"
                    classname="btn-secondary"
                    disabled={ !answerChoose }
                    onclick={ () => { saveData(indexQuestion, data) } }
                  />
                )
              }
            </ContextData.Consumer>
          </div>
          <StepsProgress
            current={ indexQuestion }
            total={ totalQuestions } />
        </div>
      </div> }
    </DataProvider>
  )
}

export default QuestionsView;
