import React, { useState, createContext } from 'react';
import { userData } from '../../../config/utils';

export const ContextData = createContext(userData);

export const DataProvider = ({ children }) => {
  const [ fieldData, setFieldData ] = useState(userData);
  const data = { fieldData, setFieldData };

  return (
    <ContextData.Provider value={ data }>
      { children }
    </ContextData.Provider>
  )
};
