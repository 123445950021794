import React, {useState, useRef, useEffect} from 'react';
import Draggable from 'react-draggable';
import './long-switch.scss';

import Rainbow from 'rainbowvis.js';
const rainbow = new Rainbow();
rainbow.setSpectrum('#cf4249', '#d4d4d4', '#00ba79');

const LongSwitch = ({value, onChange, onColorChange}) => {

  const [position, setPosition] = useState(null);
  const [backgroundColor, setBackgroundColor] = useState('#d4d4d4');
  const [textOpacity, setTextOpacity] = useState(1);

  const handler = useRef(null);
  const lastChange = useRef(null);

  useEffect(() => {
    moveToValue(value);
  }, [value]);

  useEffect(() => {

    const handleResize = () => moveToValue(lastChange.current);

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };

  }, []);

  const onSwitchPressedHandler = (e) => {
     if (e.currentTarget !== handler.current) {

      const r = e.currentTarget.getBoundingClientRect();
      const x = e.clientX - r.left;

      if (x < r.width / 3) {
        move(false);
      }

      if (x > r.width * 2 / 3) {
        move(true);
      }
    }
  };

  const onMoveHandler = () => {
    updateBackgroundColor();
    emitOnChange(null);
  };

  const onStopHandler = () => {
    move(getPosX() > 0);
  };

  const getLimit = () => {
    return (handler.current.parentNode.offsetWidth
      - handler.current.offsetHeight) / 2;
  };

  const getPosX = () => {
    const m = handler.current.style.transform.match(/\((.+?)px/);
    return m ? +m[1] : undefined;
  };

  const move = (toRight) => {
    const limit = getLimit();
    let posX = getPosX() + (toRight ? 15 : -15);

    if (toRight) {
      if (posX >= limit) {
        posX = limit;
      }
    } else if (posX <= -limit) {
      posX = -limit;
    }

    setPosition({x: posX, y: 0});
    updateBackgroundColor();

    if (Math.abs(posX) === limit) {
      setTimeout(() => setPosition(null), 0);
      emitOnChange(toRight);
    } else {
      setTimeout(() => move(toRight), 5);
      emitOnChange(null);
    }
  };

  const moveToValue = (value) => {

    const limit = getLimit();
    
    if (value === true) {
      setPosition({x: limit, y: 0});
    } else if (value === false) {
      setPosition({x: -limit, y: 0});
    } else if (value === null) {
      setPosition({x: 0, y: 0});
    }

    setTimeout(() => {
      updateBackgroundColor();
    });

    emitOnChange(value);
  };

  const updateBackgroundColor = () => {

    const limit = getLimit();
    rainbow.setNumberRange(-limit, limit);
    const color = '#' + rainbow.colourAt(getPosX());
    setBackgroundColor(color);
    onColorChange && onColorChange(color);
    setTextOpacity(1 - Math.abs(getPosX()) / limit)
  };

  const emitOnChange = (toRight) => {
    if (lastChange.current !== toRight) {
      onChange && onChange(toRight);
      lastChange.current = toRight;
    }
  };

  return (
    <div
      className="long-switch"
      style={{backgroundColor}}
      onClick={onSwitchPressedHandler}>
      <div style={{opacity: textOpacity}}>
        <div className="long-switch-text-left">
          <p className="long-switch-text-left-false">Falso</p>
        </div>
        <div className="long-switch-text-right">
          <p className="long-switch-text-right-true">Verdadero</p>
        </div>
      </div>
      <Draggable
        axis="x"
        bounds="parent"
        position={position}
        onDrag={onMoveHandler}
        onStop={onStopHandler}>
        <div
          ref={handler}
          className="handler"/>
      </Draggable>
      
    </div>
  );
};

export default LongSwitch;
