import React from 'react';
import PropTypes from 'prop-types';
import './field.scss';

const labelEl = (text, id) => (text) ? <label htmlFor={ id }>{ text }</label> : null;

const Input = ({ type, value, name, classname, min, max, placeholder, oninput, onblur, onkeydown }) => {
  const customClassName = ['box', classname].join(" ");
  return (
    <div>
      <input
        type={ type }
        name={ name }
        min={ min }
        max={ max }
        defaultValue={ value }
        className={ customClassName }
        placeholder={ placeholder }
        onInput={ oninput }
        onBlur={ onblur }
        onKeyDown={ onkeydown }
      />
    </div>
  )
}

const Check = ({ type, id, name, value, classname, labelText, checked, onchange }) => {
  const customClassName = ['check', classname].join(" ");
  return (
    <div className={ customClassName }>
      <input
        type={ type }
        id={ id }
        checked={ checked }
        name={ name }
        defaultValue={ value }
        onChange={ onchange }
      />
      { labelEl(labelText, id) }
    </div>
  )
}


Input.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  classname: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  placeholder: PropTypes.string,
  oninput: PropTypes.func,
  onblur: PropTypes.func,
  onkeydown: PropTypes.func,
}

Input.defaultProps = {
  type: 'text',
  name: '',
  value: null,
  classname: null,
  min: null,
  max: null,
  placeholder: null,
  oninput: null,
  onblur: null,
  onkeydown: null
}

Check.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  labelText: PropTypes.string,
  checked: PropTypes.bool,
  classname: PropTypes.string,
  onchange: PropTypes.func
}

Check.defaultProps = {
  type: 'radio',
  id: null,
  name: '',
  value: '',
  labelText: null,
  checked: null,
  classname: null,
  onchange: null
}

export {
  Input,
  Check
};
