import React, { useEffect, useContext, useState, useCallback } from 'react';
import Button from '../Common/Button';
import config from '../../config/result.json';
import { ContextData } from '../Common/Context';
import axios from 'axios';
import SocialNetworks from './socialNetworks/index';
import {beatsEndpoint, getDurationSecs, testEndpoint} from '../../config/utils';
import face from '../../img/congrat-face-alt.png';
import './result.scss'

const NEXT_PAGE = 'subscription';

const Result = ({ managePage }) => {
  const resultsData = config;
  const data = useContext(ContextData);
  const [grade, setGrade] = useState(0);
  const [title, setTitle] = useState('');
  const [avgPPM, setAvgPPM] = useState(0);
  const [fakeNews, setFakeNews] = useState([]);
  const [securityQuestions, setSecurityQuestions] = useState([]);

  const mapFakeNews = useCallback(() => {
    return data.fakeNews.map(news => {
      const fn = resultsData.fakeNews.answers.find(f => f.id === news.id);
      return {...news, ...fn }
    });
  }, [data, resultsData]);

  const mapSecurityQuestions = useCallback(() => {
    return data.security.map(security => {
      const sec = resultsData.security.answers.find(s => s.id === security.id);
      return {...security, ...sec }
    });
  }, [data, resultsData]);

  const getTitle = useCallback((deg) => {
    let msg = '';
    switch(true) {
      case deg <= 20:
        msg = resultsData.result[0];
        break;
      case deg <= 40 && deg >= 25:
        msg = resultsData.result[1];
        break;
      case deg <= 60 && deg >= 45:
        msg = resultsData.result[2];
        break;
      case deg === 80:
        msg = resultsData.result[3];
        break;
      default:
        msg = resultsData.result[4];
        break;
    }
    setTitle(msg);
  }, [resultsData]);

  useEffect(() => {
    document.getElementById('theme-color').content = 'black';

    (async () => {

      try {
        setFakeNews(mapFakeNews());
        setSecurityQuestions(mapSecurityQuestions());

        const testConfigKey = 'security';

        const { testId, startTimestamp, [testConfigKey]: testData, results } = data;

        if (testId && testData && Array.isArray(testData)) {

          setGrade(results.deg);
          getTitle(results.deg);

          await axios.patch(testEndpoint, {
            testId: testId,
            duration: getDurationSecs(startTimestamp),
            [testConfigKey]: testData.map(r => ({
              testId: r.id,
              answerValue: r.value
            }))
          });

          const avgPPM = await axios.get(beatsEndpoint);
          setAvgPPM(avgPPM.data.beatsData);
        }

      } catch (e) {

        console.error('Error...', e);
      }
    })();
  }, [mapFakeNews, getTitle, data, mapSecurityQuestions]);

  return (
    <div className="outcome">
      <div className="outcome-evaluation">
        <div className="wrap-theme-mobile habits" />
        <div className="">
          <p className="outcome-result-description header title">{ title }</p>
          <p className="outcome-result-description header">
            { resultsData.advice }
            <a href="https://www.levantalacabeza.info">www.levantalacabeza.info</a>
          </p>
          <div className="outcome-grade only-desktop">{ `${ grade }º` }</div>
        </div>
        <div className="outcome-image">
          <div className="img-simulate" style={ { "transform": `rotate(${ grade }deg)`, "WebkitTransform": `rotate(${ grade }deg)` } } />
        </div>
        <div className="outcome-grade only-mobile">{ `${ grade }º` }</div>
        <div className="outcome-shared">
          <div className="outcome-shared-img-left">
            <img src={face} alt="face"/>
          </div>
          <div className="outcome-shared-social-networks">
            <SocialNetworks deg={grade} />
          </div>
        </div>
      </div>
      <div className="outcome-button-slide">
        <a href="#result">Resultados<span className="icon-down" /></a>
      </div>
      <div className="outcome-result" id="result">
        <div className="outcome-result">
          <div className="outcome-result-habit">
            <div className="wrap-theme-mobile adiction" />
            <h1 className="ligth">{ resultsData.habitChanges.title }</h1>
            <h2 className="ligth">{ resultsData.habitChanges.message }</h2>
            <ul>
              { resultsData.habitChanges.tips.map((item, index) => (
                <li className="outcome-result-description" key={ index }> - <strong>{item.title }</strong> {item.description}</li>
              )) }
            </ul>
            <p className="habit-advice">
              { resultsData.habitChanges.advice }
              <a href="tel:900161515">900 16 15 15</a>
            </p>
          </div>
        </div>
        <div className="outcome-result">
          <div className="outcome-result-adiction">
            <div className="wrap-theme-mobile privacity" />
            <h1>{ resultsData.adiction.title }</h1>
            {data.adiction[0] && data.adiction[0] &&
              <h2 className="dark">{resultsData.adiction.message.replace('{v}', data.adiction[0].value)}</h2>
            }
            <p className="outcome-result-description dark">
              { resultsData.adiction.average.replace('{v}', Math.round(avgPPM)) }</p>
          </div>
          <div className="outcome-result-privacity">
            <div className="wrap-theme-mobile fakenews" />
            <h1>{ resultsData.privacity.title }</h1>
            <h2>{ resultsData.privacity.message }</h2>
            <p className="outcome-result-description">{ resultsData.privacity.tip }</p>
          </div>
        </div>
      </div>
      <div className="outcome-result">
        <div className="outcome-result">
          <div className="outcome-result-fakenews">
            <div className="wrap-theme-mobile security" />
            <h1>{ resultsData.fakeNews.title }</h1>
            <h2 className="dark">{ resultsData.fakeNews.message }</h2>
            { fakeNews && (<ul>
              { fakeNews.map((item, index) => (
                <li className="outcome-result-description dark" key={ index }>
                  <h4>{ index + 1 }. { item.phrase }</h4>
                  <p>Marcaste el titular como { item.value === 2 ? ' verdadero ' : ' falso '  } y es...</p>
                  <p><strong>{ item.answer }.</strong> { item.correctAnswer }</p>
                </li>
              )) }
            </ul>)}
          </div>
        </div>
        <div className="outcome-result">
          <div className="outcome-result-security">
            <h1>{ resultsData.security.title }</h1>
            <h2 className="dark">{ resultsData.security.message }</h2>
            { securityQuestions && (<ul>
              { securityQuestions.map((item, index) => (
                <li className="outcome-result-description" key={ index }>
                  <h4>{ index + 1 }. { item.question }</h4>
                  <p>{ item.answer }</p>
                  <p>Tu respuesta fue
                    <strong>
                      { item.ponderation ? ' incorrecta' : ' correcta' }
                    </strong>.
                  </p>
                </li>
              )) }
            </ul>)}
          </div>
        </div>
      </div>
      <div className="outcome-button-next">
        <Button
          type="button"
          text="SIGUIENTE"
          classname="btn-terciary"
          onclick={ () => { managePage(NEXT_PAGE) } }
        />
      </div>
    </div>
  )
}

export default Result;
