import React from 'react';
import AnimatedIntroView from '../../Common/AnimatedIntroView';
import './animated-intro-habits-change.scss';

import config from '../../../config/config.json';
const NEXT_PAGE = 'questionHabitChanges';

const AnimatedIntroHabitsChange = ({managePage}) => (
  <AnimatedIntroView
    className="animated-intro-habits-change"
    themeColor="#2f26c5"
    title={ config.habitChanges.title }
    firstAnimationType="habitsChangeIntro"
    firstAnimationDuration={2500}
    description={ config.habitChanges.description }
    secondAnimationType="squareAnswers"
    instructions="Pulsa en la opción elegida"
    onStartPressed={() => {managePage(NEXT_PAGE)}}/>
);

export default AnimatedIntroHabitsChange;
