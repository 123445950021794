import React from 'react';
import QuestionsView from '../../Common/QuestionsView';

const NEXT_PAGE = 'animatedIntroAdiction';

const QuestionHabitChanges = ({managePage, resetScrollFn}) => {

  return (
    <QuestionsView
      testIndex={0}
      configItemName="habitChanges"
      resetScrollFn={resetScrollFn}
      onFinish={() => managePage(NEXT_PAGE)}
      colorTitle="color-title-white" />
  );
};

export default QuestionHabitChanges;
