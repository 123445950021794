import React, { Fragment } from 'react';
import Button from '../Button';

import './modal.scss';

const Modal = ({ text, visible, onclick }) => {
  const classModal = visible ? '' : 'hide';

  return (
    <Fragment>
      <div className={ `modal-wrap ${ classModal }` }></div>
      <div className={ `modal-outer ${ classModal }` }>
        <div className="modal-inner">
          <p>{ text }</p>
          <div className="modal-btn">
            <Button
              type="button"
              text="x"
              classname="btn-empty"
              onclick={ () => { onclick() } }
            />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default Modal;