import React, { useEffect, useState } from 'react';
import './test-adiction.scss';
import TypingTestSentence from './TypingTestSentence';
import Button from '../../Common/Button'
import { DataProvider, ContextData } from '../../Common/Context';

import config from '../../../config/config';
import TestsProgress from '../../Common/TestsProgress';

const NEXT_PAGE = 'animatedIntroPrivacity';

const TestAdiction = ({ managePage, resetScrollFn }) => {
  const { title, ponderation, tests } = config.adiction;
  const totalTests = tests.length - 1;
  const [indexTest, setIndexTest] = useState(0);
  const [timeTest, setTimeTest] = useState('');
  const [textAreaValue, setTextAreaValue] = useState('');
  const [currentText, setCurrentText] = useState('');

  useEffect(() => {
    const indexText = Math.floor(Math.random() * tests[indexTest].texts.length);
    const randomText = tests[indexTest].texts[indexText];
    setCurrentText(randomText);
  }, [indexTest, tests]);

  const saveData = (indexTest, data) => {
    data.setFieldData(() => {
      let p;
      let ppm = Math.round((60000 * currentText.length) / timeTest);
      if(ppm >= tests[indexTest].answerLessThan * 2) {
        p = ponderation;
      } else if(ppm > tests[indexTest].answerLessThan ) {
        p = ponderation / 2;
      } else {
        p = 0;
      }

      data.fieldData['adiction'].push({
        id: tests[indexTest].id,
        value: ppm,
        ponderation: p
      })

      return { ...data.fieldData };
    })

    if (indexTest < totalTests) {
      setIndexTest(indexTest + 1)
      setTimeTest('')
      setTextAreaValue('')
      resetScrollFn && resetScrollFn();
    } else {
      managePage(NEXT_PAGE)
    }
  }

  const handleKeyEnter = (e, data) => {
    if (timeTest.length && e.keyCode === 13) {
      saveData(indexTest, data)
    }
  }

  return (
    <DataProvider>
      <div className="test-adiction-app">
        <TestsProgress index={1}/>
        <div className="left">
          <h1 className="title">{title}</h1>
          <TypingTestSentence
            sentence={currentText}
            userInput={textAreaValue}
            onMatch={e => { setTimeTest(`${e}`) }} />
        </div>

        <div className="right">
          <div className="typing-test-wrapper">
            <div className="progress-and-typing">
              <h1 className="title">{title}</h1>
              <TypingTestSentence
                sentence={currentText}
                userInput={textAreaValue}
                onMatch={e => { setTimeTest(`${e}`) }} />
              <ContextData.Consumer>
                {
                  data => (
                    <textarea
                      autoFocus
                      onKeyDown={ (e) => handleKeyEnter(e, data) }
                      value={textAreaValue}
                      onChange={e => setTextAreaValue(e.target.value)}
                      spellCheck={false} />
                  )
                }
              </ContextData.Consumer>
            </div>

            <div className="mobile-test">
              <div className="mobile-img" />
              <p>Sería fantástico que hicieses el test con tú móvil</p>
            </div>
          </div>

          <div className="button">
            <ContextData.Consumer>
              {
                data => (
                  <Button
                    type="button"
                    text="Siguiente"
                    classname="btn-secondary"
                    disabled={timeTest.length ? false : true}
                    onclick={() => { saveData(indexTest, data) }}
                  />
                )
              }
            </ContextData.Consumer>
          </div>
        </div>
      </div>
    </DataProvider>
  )
}

export default TestAdiction;
