import React from 'react';
import PropTypes from 'prop-types';
import './button.scss';

const Button = ({ text, onclick, type, classname, disabled }) => {
  const customClassName = ['btn', classname].join(" ");
  return <button type={ type } className={ customClassName } onClick={ onclick } disabled={ disabled }>{ text }</button>
}

Button.propTypes = {
  text: PropTypes.string.isRequired,
  type: PropTypes.string,
  classname: PropTypes.string,
  disabled: PropTypes.bool.isRequired
}

Button.defaultProps = {
  text: '',
  type: null,
  classname: null,
  disabled: false
}

export default Button;
