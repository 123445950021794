import React from 'react';
import './step-progress.scss';

const StepsProgress = ({current, total}) => {

  return (
    <div className="step-progress">
      {[...Array(total).keys()].map(i =>
        <div
          key={i}
          className={
            'step-progress-bullet ' +
            (i <= current ? 'highlighted' : '')
          } />
      )}
    </div>
  )
};

export default StepsProgress;
