import React from 'react';
import SocialNetworksThemesPanel from './SocialNetworksThemesPanel';
import { DataProvider, ContextData } from '../../Common/Context';

import config from '../../../config/config';
import './game-privacity.scss';
import TestsProgress from '../../Common/TestsProgress';

const NEXT_PAGE = 'animatedIntroFakeNews';

const GamePrivacity = ({ managePage }) => {
  const { title, description, socialsGame } = config.privacity;

  const saveData = (e, data) => {
    data.setFieldData(() => {
      data.fieldData['privacity'] = e;

      return data.fieldData;
    });

    if(e.length === socialsGame.socialsList.length) {

      setTimeout(() => {
        managePage(NEXT_PAGE);
      }, 1000);
    }
  };

  return (
    <DataProvider>
      <div className="game-privacity-app">
        <TestsProgress index={2}/>
        <div className="left-column">
          <div className="title-description">
            <h1>{title}</h1>
            <p>{description}</p>
          </div>
        </div>
        <div className="right-column">
          <ContextData.Consumer>
            { data => (
              <SocialNetworksThemesPanel
                contents={socialsGame}
                onChange={ (e) => { saveData(e, data) }}
                onclick={ (e) => { saveData(e, data); managePage(NEXT_PAGE) } }
                data={data}
              />
            ) }
          </ContextData.Consumer>
        </div>
      </div>
    </DataProvider>
  );
};

export default GamePrivacity;
