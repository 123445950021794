import React, {useState, useRef, useEffect} from 'react';
import Button from '../../../Common/Button';

import './social-networks-themes-panel.scss';
import Draggable from 'react-draggable';

const SocialNetworksThemesPanel = ({contents, onChange, onclick}) => {

  const {ponderation, socialsList, themesList, themesStrings} = contents;

  const [socials, setSocials] = useState(socialsList.map(() => ({
    isUsed: false
  })));

  const [themes, setThemes] = useState(themesList.map(() => ({
    isJustUsed: false,
    isHover: false
  })));

  const [assignmentsStack, setAssignmentsStack] = useState([]);

  const themesUlRef = useRef(null);

  const isDragging = useRef(null);

  useEffect(() => {

    // Needed to prevent iOS scrolling while dragging

    const handleTouchMove = (e) => {
      if (isDragging.current) {
        e.preventDefault();
      }
    };

    window.document.addEventListener('touchmove',
      handleTouchMove, {passive: false});

    return () => {
      window.document.removeEventListener('touchmove', handleTouchMove);
    };

  }, []);

  useEffect(() => {

    onChange && onChange(getAssignments(assignmentsStack));

  }, [assignmentsStack, socialsList, themesList, onChange]);

  const getAssignments = (assignmentsStack) => {

    return assignmentsStack.map(({socialIndex, themeIndex}) => ({
      id: socialsList[socialIndex].id,
      value: themesList[themeIndex],
      ponderation: socialsList[socialIndex].theme
        === themesList[themeIndex] ? ponderation : 0
    }));
  };

  const onStartHandler = () => {
    isDragging.current = true;
  };

  const onMoveHandler = (e) => {

    const hovers = [...themesUlRef.current.childNodes]
      .map(t => ({isHover: isElemXCenterInsideElemY(e.target, t)}));

    setThemes(themes =>
      themes.map((theme, themeIndex) => ({...theme, ...hovers[themeIndex]}))
    );
  };

  const onStopHandler = (e, socialIndex) => {

    isDragging.current = false;

    const themeIndex = [...themesUlRef.current.childNodes]
      .findIndex(t => isElemXCenterInsideElemY(e.target, t));

    if (themeIndex > -1) {

      setSocials(socials =>
        socials.map((social, sIndex) =>
          sIndex === socialIndex ? {isUsed: true} : {...social}
        )
      );

      setThemes(themes =>
        themes.map((theme, tIndex) =>
          tIndex === themeIndex ? {isJustUsed: true, isHover: false} : {...theme}
        )
      );

      setAssignmentsStack(assignmentsStack => [...assignmentsStack, {socialIndex, themeIndex}]);
    }
  };

  const isElemXCenterInsideElemY = (elemX, elemY) => {

    let r = elemX.getBoundingClientRect();

    const p = {
      x: r.x ? r.x + r.width / 2 : 0,
      y: r.y ? r.y + r.height / 2 : 0
    };

    r = elemY.getBoundingClientRect();

    return r.x <= p.x && p.x <= (r.x + r.width) &&
      r.y <= p.y && p.y <= (r.y + r.height);
  };

  const onUndoPressHandler = () => {

    const lastAssign = assignmentsStack[assignmentsStack.length - 1];

    setAssignmentsStack(assignmentsStack => assignmentsStack.slice(0, -1));

    setSocials(socials =>
      socials.map((social, sIndex) =>
        sIndex === lastAssign.socialIndex ? {isUsed: false} : {...social}
      )
    );

    setThemes(themes =>
      themes.map((theme, tIndex) =>
        tIndex === lastAssign.themeIndex ? {...theme, isJustUsed: false} : {...theme}
      )
    );
  };

  const unhighlightingAnimationEnded = () => {

    setThemes(themes =>
      themes.map((theme) => ({...theme, isJustUsed: false}))
    );
  };

  return (
    <div className="social-networks-themes-panel">
      <div className="lists-container">
        <ul className="social-networks">
          { socialsList.map((sn, i) => (
            <li key={i} className={sn.code}>
              <div className="slot"/>
              <Draggable
                onStart={onStartHandler}
                onDrag={onMoveHandler}
                onStop={(e) => onStopHandler(e, i)}
                position={{x: 0, y:0}}>
                <div
                  className="draggable"
                  style={{display: socials[i].isUsed ? 'none' : 'block'}}/>
              </Draggable>
            </li>
          ))}
        </ul>
        <ul className="themes" ref={themesUlRef}>
          { themesList.map((t, i) => (
            <li
              key={i}
              onTransitionEnd={unhighlightingAnimationEnded}
              className={
                (themes[i].isJustUsed ? 'unhighlighting ' : '') +
                (themes[i].isHover ? 'highlighted' : '')
              }>
              { themesStrings[t] }
            </li>
          ))}
        </ul>
      </div>
      <div className="buttons-container">
        <Button
          type="button"
          text="Finalizar"
          classname="btn-secondary"
          disabled={ assignmentsStack.length ? false : true }
          onclick={ () => { onclick(getAssignments(assignmentsStack)) } }
        />
        <Button
          text="Deshacer"
          type="button"
          classname="btn-secondary"
          disabled={!assignmentsStack.length}
          onclick={onUndoPressHandler} />
      </div>
    </div>
  );
};

export default SocialNetworksThemesPanel;
