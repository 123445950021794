import React from 'react';
import './header.scss';
import logo_red from '../../img/levanta_la_cabeza_red.svg';
import logo_white from '../../img/levanta_la_cabeza_white.svg';

const Header = ({page}) => {

  const getLogo = () => {
    const red = ['about', 'result', 'subscription',
      'thanks', 'thanksSubscribed', 'browserNotSupported'];

    if (red.some(item => item === page)) {
      return logo_red;
    }

    return logo_white;
  }

  return (
    <header className="header-app">
      <a href="https://compromiso.atresmedia.com/levanta-la-cabeza/" title="Levanta la Cabeza" target="_blank">
        <img src={getLogo()} alt="logo"/>
      </a>
    </header>
  );
}

export default Header;
