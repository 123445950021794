import React, {useState, useEffect, useRef, useCallback} from 'react';
import Button from '../../Common/Button';
import LongSwitch from './LongSwitch';
import StepsProgress from '../../Common/StepsProgress';
import { getRandomIndexsOfArray } from '../../../config/utils';

import { DataProvider, ContextData } from '../../Common/Context';

import './test-fakenews.scss';
import config from '../../../config/config'
import TestsProgress from '../../Common/TestsProgress';

const NEXT_PAGE = 'animatedIntroSecurity';

const TestFakeNews = ({ managePage, resetScrollFn }) => {
  const { title, ponderation, fakeNews, totalQuestions } = config.fakeNews;
  const [ imageColor, setImageColor ] = useState('none');
  const [ longSwitchValue, setLongSwitchValue ] = useState(null);
  const [ indexTestFakeNews, setIndexTestFakeNews ] = useState(0);
  const [ questions, setQuestions ] = useState([]);

  const selectionTimeout = useRef(0);

  const getNewQuestions = useCallback(() =>
    getRandomIndexsOfArray(totalQuestions, fakeNews).map(i => fakeNews[i]),
  [fakeNews, totalQuestions]);

  const selectFakeNewsQuestions = useCallback(() => {
    setQuestions(getNewQuestions());
  }, [getNewQuestions]);

  useEffect(() => {
    selectFakeNewsQuestions();
    if (selectionTimeout.current) {
      clearTimeout(selectionTimeout.current);
    }
  }, [selectFakeNewsQuestions]);

  const onLongSwitchChangeHandler = (value, data) => {

    setLongSwitchValue(value);

    if (selectionTimeout.current) {
      clearTimeout(selectionTimeout.current);
    }

    if (value !== null) {

      selectionTimeout.current = setTimeout(() => {
        saveData(indexTestFakeNews, value, data);
      }, 1500);
    }
  };

  const saveData = (indexTestFakeNews, value, data) => {
    data.setFieldData(() => {

      const val = value ? 2 : 1;

      data.fieldData['fakeNews'].push({
        id: questions[indexTestFakeNews].id,
        value: val,
        ponderation: questions[indexTestFakeNews].answerValue !== val ? ponderation : 0
      })

      return { ...data.fieldData };
    })

    if (indexTestFakeNews < (totalQuestions - 1)) {
      setIndexTestFakeNews(indexTestFakeNews + 1)
      setLongSwitchValue(null);
      resetScrollFn();
    } else {
      managePage(NEXT_PAGE)
    }
  };

  return (
    <DataProvider>
      {!!questions.length && (<div className="test-fakenews-app">
        <TestsProgress index={3}/>
        <div className="left-column">
          <p className="fake-news-title">
            Fake news
          </p>
          <p className="news-text">
            { questions[indexTestFakeNews].text }
          </p>
          <StepsProgress
            title={ title }
            current={ indexTestFakeNews }
            total={ totalQuestions } />
        </div>
        <div className="right-column">
          <div
            className="news-image"
            style={{
              backgroundImage: `url("${require('./images/' + questions[indexTestFakeNews].image + '.jpg')}")`
            }}>
            { longSwitchValue !== null ?
              <p>{ longSwitchValue ? 'Verdadero' : 'Falso' }</p> :
              '' }
          </div>
          <ContextData.Consumer>
            {
              data => (
                <div className="controls-container">
                  <div className="long-switch-container">
                    <LongSwitch
                      value={longSwitchValue}
                      onChange={value => onLongSwitchChangeHandler(value, data)}
                      onColorChange={color => setImageColor(color)}/>
                  </div>
                  <div className="button-container">
                  </div>
                  <Button
                    onclick={ () => { saveData(indexTestFakeNews, longSwitchValue, data) } }
                    text="Confirmar"
                    type="button"
                    disabled={ longSwitchValue === null }
                    classname="btn-secondary"
                  />
                </div>
              )
            }
          </ContextData.Consumer>
          <StepsProgress
            title={ title }
            current={ indexTestFakeNews }
            total={ totalQuestions } />
        </div>
      </div>
    )}
    </DataProvider>
  );
};

export default TestFakeNews;
