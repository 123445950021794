import React from 'react';
import './tests-progress.scss';

const TestsProgress = ({index}) => {

  return (
    <div className="tests-progress">
      {[...Array(5).keys()].map(i =>
        <div
          key={i}
          className={(i <= index ? 'visible' : '')} />
      )}
    </div>
  );
};

export default TestsProgress;
