import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";
import Button from '../Common/Button';
import { Input, Check } from '../Common/Field';
import { DataProvider, ContextData } from '../Common/Context';
import FormDataValue from './formDataUser';
import './about.scss';
import {getDurationSecs, getUserAgentKey, testEndpoint} from '../../config/utils';

const NEXT_PAGE = 'animatedIntroHabitChanges';

const About = ({ managePage, resetScrollFn }) => {
  const userData = useContext(ContextData);
  const formDataValue = new FormDataValue(userData.userInfo);
  const [ formData, setFormData ] = useState(formDataValue.getData());
  const [ isInfoScreenShown, setIsInfoScreenShown] = useState(false);
  const [ captcha, setCaptcha ] = useState(null);

  useEffect(() => {
    document.getElementById('theme-color').content = 'black';
  }, []);

  const fieldValidate = (validations, field) => {
    validations.forEach((validation) => {
      const messageError = validation(formData[field].value);
      const isValid = messageError.length ? false : true;

      formData[field].isValid = isValid;
      formData[field].messageError = messageError;

      setFormData({...formData});
    })
  }

  const handleChange = (field, value) => {
    formData[field].value = value;

    setFormData({ ...formData });

    fieldValidate(formData[field].validations, field)
  }

  const handleSubmit = (data) => {

    if (formData.age.isValid && formData.gender.isValid && formData.code.isValid) {

      const postData = {
        captchaToken: captcha,
        duration: getDurationSecs(data.fieldData.startTimestamp),
        age: +formData.age.value,
        gender: formData.gender.value,
        zipCode: formData.code.value,
        platform: getUserAgentKey()
      };

      (async () => {
        try {
          const res = await axios.post(testEndpoint, postData);
          data.fieldData.testId = res.data.testId
        } catch (e) {
          console.error('Error sending the test data...', e);
        }
      })();

      managePage(NEXT_PAGE);
    }
  };

  const isDisabled = !formData.age.isValid || !formData.gender.isValid || !formData.code.isValid || !captcha ? true : false;

  return (
    <DataProvider>
      <div className={'about-app ' + (isInfoScreenShown ? 'is-info-screen-shown' : '')}>
        <h2>Cuéntanos algo sobre ti</h2>
        <div className="wrapper">
          <div className="form">
            <form>
              <div className="about-info-app age">
                <label className="title-input">Edad</label>
                <div>
                  <Input
                    type="number"
                    name="age"
                    classname={ formData.age.messageError && formData.age.messageError.length ? 'field-error' : '' }
                    value={ formData.age.value }
                    min={0}
                    max={110}
                    onblur={(e) => { handleChange('age', e.target.value) }}
                  />
                </div>
                <div className="error">{ formData.age.messageError }</div>
              </div>
              <div className="about-info-app">
                <span className="title-input">Género</span>
                <div className="group">
                  <Check
                    id="m"
                    type="radio"
                    classname="about"
                    name="gender"
                    value="M"
                    checked={ formData.gender.value === 'M' ? true : false }
                    onchange={ (e) => { handleChange('gender', e.target.value) } }
                    labelText="Hombre"
                  />
                </div>
                <div className="group">
                  <Check
                    id="f"
                    type="radio"
                    classname="about"
                    name="gender"
                    value="F"
                    checked={ formData.gender.value === 'F' ? true : false }
                    onchange={ (e) => { handleChange('gender', e.target.value) } }
                    labelText="Mujer"
                  />
                </div>
                <div className="group">
                  <Check
                    id="o"
                    type="radio"
                    classname="about"
                    name="gender"
                    value="O"
                    checked={ formData.gender.value === 'O' ? true : false }
                    onchange={ (e) => { handleChange('gender', e.target.value) } }
                    labelText="Otro"
                  />
                </div>
                <div className="error">{ formData.gender.messageError }</div>
              </div>
              <div>
                <label className="title-input">Código postal</label>
                <div>
                  <ContextData.Consumer>
                    {
                      data => (
                        <Input
                          type="number"
                          name="code"
                          value={ formData.code.value }
                          classname={ formData.code.messageError && formData.code.messageError.length ? 'field-error' : '' }
                          oninput={(e) => { handleChange('code', e.target.value) }}
                          onkeydown={ (e) => {
                            if(e.keyCode === 13){
                              handleSubmit(data)
                            }
                          } }
                        />
                      )
                    }
                  </ContextData.Consumer>
                </div>
                <div className="error">{ formData.code.messageError }</div>
              </div>
            </form>
            <div className="captcha">
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                onChange={setCaptcha}
              />
            </div>
            <div className="desc hide-for-desktop">
              <p>La elaboración de este test es anónima. Esta información solo se utilizará con fines estadísticos y en ningún momento se asociará a ningún dato con el que se te pueda relacionar. Al final del test, si lo deseas, podrás dejarnos tu email para seguir la información o las acciones que generemos desde Levanta la cabeza. Este test tiene como fin el entretenimiento y en ningún caso refleja un resultado médico. Si crees que tú o alguien de tu entorno tiene un problema con la tecnología puedes contactar con el servicio de orientación de la FAD en el <a href="tel:+900161515">900161515</a>.</p>
            </div>
          </div>
          <div className="info">
            <div className="info-container">
              <div className="desc hide-for-mobile">
                <p>La elaboración de este test es anónima. Esta información solo se utilizará con fines estadísticos y en ningún momento se asociará a ningún dato con el que se te pueda relacionar. Al final del test, si lo deseas, podrás dejarnos tu email para seguir la información o las acciones que generemos desde Levanta la cabeza. Este test tiene como fin el entretenimiento y en ningún caso refleja un resultado médico. Si crees que tú o alguien de tu entorno tiene un problema con la tecnología puedes contactar con el servicio de orientación de la FAD en el <a href="tel:+900161515">900161515</a>.</p>
              </div>    
            </div>
            <div className="next-button">
              <Button
                text="Continuar"
                disabled={ isDisabled }
                classname="btn-primary"
                onclick={() => { resetScrollFn(); setIsInfoScreenShown(true);}} />
            </div>
            <div className="info-container">
              <div className="desc">
                <p>¿Puede el uso de la tecnología alterar nuestras capacidades cognitivas? ¿somos conscientes de cómo nos afecta su uso intensivo? ¿dejaremos de hablar cara a cara? ¿somos capaces de desengancharnos de nuestro smartphone? ¿cambian las fake news nuestra percepción del mundo? ¿influye la pandemia del coronavirus y la ‘nueva normalidad’ en cómo utilizamos nuestros dispositivos? Es el momento de dar respuestas.</p>
                <p>Completa este sencillo test y descubrirás si haces un uso responsable de la tecnología. Son cinco bloques y solo tardarás cinco minutos.</p>
              </div>
              <div className="buttons-container">
                <div>
                  <Button
                    text="Volver"
                    classname="btn-primary"
                    onclick={() => setIsInfoScreenShown(false)} />
                </div>
                <div>
                  <ContextData.Consumer>
                    {
                      data => (
                        <Button
                          type="submit"
                          text="Empezar"
                          disabled={ isDisabled }
                          classname="btn-primary"
                          onclick={ () => { handleSubmit(data) } }
                        />
                      )
                    }
                  </ContextData.Consumer>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DataProvider>
  );
}

export default About;
