import React, { useState, useEffect, useRef } from 'react';
import Header from './Components/Header';
import Home from './Components/Home';
import About from './Components/About';
import AnimatedIntroHabitsChange from './Components/HabitChanges/AnimatedIntroHabitChanges';
import QuestionHabitChanges from './Components/HabitChanges/QuestionHabitChanges';
import AnimatedIntroAdiction from './Components/Adiction/AnimatedIntroAdiction';
import TestAdiction from './Components/Adiction/TestAdiction';
import AnimatedIntroPrivacity from './Components/Privacity/AnimatedIntroPrivacity';
import GamePrivacity from './Components/Privacity/GamePrivacity';
import AnimatedIntroFakeNews from './Components/FakeNews/AnimatedIntroFakeNews';
import TestFakeNews from './Components/FakeNews/TestFakeNews';
import AnimatedIntroSecurity from './Components/Security/AnimatedIntroSecurity';
import QuestionSecurity from './Components/Security/QuestionSecurity';
import Result from './Components/Result/index';
import Subscription from './Components/Subscription';
import Thanks from './Components/Thanks';
import BrowserNotSupported from './Components/BrowserNotSupported';
import './App.scss';

const DEFAULT_PAGE = 'home';
const BROWSER_NOT_SUPPORTED_PAGE = 'browserNotSupported';

const App = () => {
  const isIE = /*@cc_on!@*/false || !!document.documentMode;

  const [page, setPage] = useState(isIE ?
    BROWSER_NOT_SUPPORTED_PAGE : DEFAULT_PAGE);

  const scrollableContent = useRef(null);

  const resetScroll = () => {
    if (scrollableContent.current) {
      scrollableContent.current.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {

      // Prevents zoom in iOS
      window.document.addEventListener('touchmove', e => {
        if(e.scale !== 1) {
          e.preventDefault();
        }
      }, {passive: false});

      // To disable scroll bouncing
      window.document.body.className = 'fixed';
    }

  }, []);

  const renderComponent = (pageIndex, setPage, resetScrollFn) => {
    switch (pageIndex) {
      case 'home':
        return <Home managePage={ setPage }/>;
      case 'about':
        return <About managePage={ setPage } resetScrollFn={resetScrollFn}/>;
      case 'animatedIntroHabitChanges':
        return <AnimatedIntroHabitsChange managePage={ setPage }/>;
      case 'questionHabitChanges':
        return <QuestionHabitChanges managePage={ setPage } resetScrollFn={resetScrollFn}/>;
      case 'animatedIntroAdiction':
        return <AnimatedIntroAdiction managePage={ setPage }/>;
      case 'testAdiction':
        return <TestAdiction managePage={ setPage } resetScrollFn={resetScrollFn}/>;
      case 'animatedIntroPrivacity':
        return <AnimatedIntroPrivacity managePage={ setPage }/>;
      case 'gamePrivacity':
        return <GamePrivacity managePage={ setPage }/>;
      case 'animatedIntroFakeNews':
        return <AnimatedIntroFakeNews managePage={ setPage }/>;
      case 'testFakeNews':
        return <TestFakeNews managePage={ setPage } resetScrollFn={resetScrollFn}/>;
      case 'animatedIntroSecurity':
        return <AnimatedIntroSecurity managePage={ setPage }/>;
      case 'questionSecurity':
        return <QuestionSecurity managePage={ setPage } resetScrollFn={resetScrollFn}/>;
      case 'result':
        return <Result managePage={ setPage }/>;
      case 'subscription':
        return <Subscription managePage={ setPage } />;
      case 'thanks':
        return <Thanks managePage={ setPage } />;
      case 'thanksSubscribed':
        return <Thanks managePage={ setPage } hasSubscribed={true}/>;
      case 'browserNotSupported':
        return <BrowserNotSupported />;
      default:
        return <div>Ocurrio un error</div>
    }
  };

  //Restore the scroll position on view change
  resetScroll();

  return (
    <div
      ref={scrollableContent}
      className={`bg-${page}`}>
      <Header page={page} />
      { renderComponent(page, setPage, resetScroll) }
    </div>
  )
}

export default App;
