import React from 'react';
import AnswersList from '../AnswersList';

import './question-item.scss';

const QuestionItem = ({ item, classname, handleChange }) => (
  <div>
    <p className="question-item-description">{ item.text }</p>
    <AnswersList
      classname={ classname }
      idQuestion={ item.id }
      answers={item.answers}
      handleChange={ handleChange }/>
  </div>
);

export default QuestionItem;
